<template>
  <v-container class="Product_bar">
    <div class="d-flex flex-row justify-space-between">
      <div class="align-self-center">
        <h4>NEW USER ACCOUNT CREATION</h4>
      </div>
      <b-button @click="sendEmails"> Test Send Email </b-button>
      <div class="align-self-center">
        <b-button-group class="ml-2">
          <b-button @click="goBack" class="back-button">Back</b-button>
        </b-button-group>
      </div>
    </div>
    <v-row no-gutters class="ml-7"> </v-row>
    <v-row class="mt-3">
      <v-col cols="6">
        <v-row>
          <v-col cols="4" id="FIRST NAME">
            <label class="small-lblhead"
              ><b>FIRST NAME</b>
              <i class="fa fa-asterisk ml-1 red--text"></i>
            </label>
            <b-form-input
              class="small-bold-input"
              v-model="userData.first_name"
              placeholder="First Name"
              required
            >
            </b-form-input>
          </v-col>
          <v-col cols="4" id="LAST NAME">
            <label class="small-lblhead"
              ><b>LAST NAME</b><i class="fa fa-asterisk ml-1 red--text"></i
            ></label>
            <b-form-input
              v-model="userData.last_name"
              class="small-bold-input"
              placeholder="Last Name"
              required
            >
            </b-form-input>
          </v-col>
          <v-col cols="4" id="UID">
            <label class="small-lblhead"
              ><b>UID</b><i class="fa fa-asterisk ml-1 red--text"></i
            ></label>
            <b-form-input
              v-model="userData.uid"
              class="small-bold-input"
              placeholder="Last Name"
              required
            >
            </b-form-input>
          </v-col>
        </v-row>
        <v-row class="small-lblhead mb-1">
          <template id="EMAIL">
            <div>
              <label class="small-lblhead"
                ><b>EMAIL</b><i class="fa fa-asterisk ml-1 red--text"></i
              ></label>
              <b-form-input
                class="small-bold-input"
                v-model="userData.email"
                placeholder="Email"
                :rules="[(v) => !!v || 'This field is required']"
              >
              </b-form-input>
            </div>
          </template>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="6">
            <template id="Primay Phone">
              <div>
                <label class="small-lblhead"><b>MAIN PHONE</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.phone"
                  placeholder="Main Phone"
                  maxlength="15"
                  v-mask="mask.phone"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="TEXT">
              <div>
                <label class="small-lblhead"><b>TEXT</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.text_no"
                  placeholder="Text"
                  maxlength="15"
                  v-mask="mask.phone"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="6">
            <template id="City">
              <div>
                <label class="small-lblhead"><b>CITY</b></label>
                <b-form-select
                  class="small-bold-text"
                  v-model="userData.city_id"
                  placeholder="City"
                  :options="list_city"
                  value-field="id"
                  text-field="text"
                  disabled-field="notEnabled"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </b-form-select>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="State">
              <div>
                <label class="small-lblhead"><b>STATE</b></label>
                <b-form-select
                  class="small-bold-text"
                  v-model="userData.state_id"
                  placeholder="State"
                  :options="list_states"
                  value-field="id"
                  text-field="text"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </b-form-select>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="6">
            <template id="COMPANY">
              <div>
                <label class="small-lblhead"><b>COMPANY</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.company"
                  placeholder="Company"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="ZIPCODE">
              <div>
                <label class="small-lblhead"><b>ZIPCODE</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.zip"
                  placeholder="Zip"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="6">
            <template id="FACEBOOK">
              <div>
                <label class="small-lblhead"><b>FACEBOOK</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.facebook"
                  placeholder="Facebook"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="INSTAGRAM">
              <div>
                <label class="small-lblhead"><b>INSTAGRAM</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.instagram"
                  placeholder="Instagram"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="WHATSAPP">
              <div>
                <label class="small-lblhead"><b>WHATSAPP</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.whatsapp"
                  placeholder="WhatsApp"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="LINKEDIN">
              <div>
                <label class="small-lblhead"><b>LINKED IN</b></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.linkedin"
                  placeholder="LinkedIN"
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="12" class="small-lblhead">
            <template id="ACCOUNT TYPE">
              <div class="small-lblhead">
                <label class="small-lblhead"><b>ACCOUNT TYPE</b></label>
                <b-input-group size="sm" class="small-lblhead">
                  <template #prepend class="small-bold-input">
                    <b-input-group-text class="small-prepend"
                      >Account Type</b-input-group-text
                    >
                  </template>
                  <b-form-select
                    size="sm"
                    v-model="userData.user_type_id"
                    :options="list_user_types"
                    class="mb-3"
                    value-field="id"
                    text-field="text"
                    disabled
                    :rules="[(v) => !!v || 'This field is required']"
                  ></b-form-select>
                </b-input-group>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="small-lblhead">
          <v-col cols="6">
            <template id="PASSWORD">
              <div>
                <label class="small-lblhead"
                  ><b>PASSWORD</b><i class="fa fa-asterisk ml-1 red--text"></i
                ></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="userData.django_pwd"
                  type="password"
                  placeholder="Password"
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
          <v-col cols="6">
            <template id="CONFIRM PASSWORD">
              <div>
                <label class="small-lblhead"
                  ><b>CONFIRM PASSWORD</b
                  ><i class="fa fa-asterisk ml-1 red--text"></i
                ></label>
                <b-form-input
                  class="small-bold-input"
                  v-model="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  :rules="[(v) => !!v || 'This field is required']"
                  required
                >
                </b-form-input>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" id="Right Side Columns">
        <div class="mt-5">
          <input
            type="file"
            ref="file"
            @change="updatePreview"
            class="file-input"
            id="file-input"
          />
          <label for="file-input" class="mb-5 px-2">
            <i class="fa fa-folder mr-1"></i>
            Upload Profile Image
          </label>
        </div>
        <form
          v-if="!!profileImageUrl"
          @submit.prevent="sendFile"
          enctype="multipart/form-data"
        >
          <v-row class="user-profile ml-1 mb-5">
            <v-img
              :src="profileImageUrl"
              contain
              width="100%"
              height="100%"
              class="preview-image"
              @click="downloadFile(userData.file, userData.filename)"
              outlined
            >
            </v-img>
          </v-row>
        </form>
        <div v-else class="grey--text mb-2">No File Uploaded!</div>
        <v-btn
          :disabled="!formIsValid"
          large
          rounded
          color="primary"
          depressed
          block
          @click="submitDetails"
          >create account</v-btn
        >
      </v-col>
    </v-row>
    <div v-if="dlgError">
      <b-modal
        id="modal-center"
        v-model="dlgError"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-left"></p>
            <b-button size="sm" class="float-right" @click="dlgError = false">
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";

export default {
  name: "Create Account",
  directives: {
    mask,
  },

  created() {
    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));
    console.log("UserData: ", this.userData);
    CommonServices.getList("Cities")
      .then((res) => {
        console.log("Cities", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_city.push({ id: code.id, text: code.city })
        );
        console.log("list_city: ", this.list_city);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("States")
      .then((res) => {
        console.log("States", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_states.push({ id: code.id, text: code.state })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    //exclude the Admin level from the list
    CommonServices.getList("PermissionLevels", {
      params: {
        permission_level_id__gte: 2,
      },
    })
      .then((res) => {
        console.log("PermissionLevels", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_user_types.push({
            id: code.permission_level_id,
            text: code.permission_level,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `Users`,
      componentURL_base: `Users`,
      userList: [],
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      dialogSelectUser: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      confirmPassword: "",

      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,

      items: [],
      list_city: [],
      list_states: [],
      list_user_types: [],

      userData: {
        id: "",
        uid: "",
        first_name: "",
        last_name: "",
        full_name: "",
        city_id: null,
        state_id: null,
        zip: null,
        company: "",
        phone: null,
        text_no: null,
        facebook: null,
        email: null,
        instagram: null,
        whatsapp: null,
        linkedin: null,
        verify_method: null,
        django_pwd: null,
        user_type_id: 4,
        user_type: "",
        active: "",
        admin: "",
        user_photo_file: "",
        timestamp: null,
        user_photo_file_name: "",
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CreateAccount",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formIsValid() {
      return (
        !!this.userData.first_name &&
        !!this.userData.last_name &&
        !!this.userData.email &&
        !!this.userData.user_type_id &&
        !!this.userData.django_pwd
      );
    },
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    submitDetails() {
      if (this.userData.django_pwd !== this.confirmPassword) {
        this.dlgError = true;
        this.errorTitle = "Passwords Error!";
        this.errorText =
          "The passwords that you have entered do not match.  Please correct and try again.";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (this.userData.django_pwd.length < 6) {
        this.dlgError = true;
        this.errorTitle = "Passwords Error!";
        this.errorText = "The password should be atleast 6 character long.";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (!this.validateEmail(this.userData.email)) {
        this.dlgError = true;
        this.errorTitle = "Email Error!";
        this.errorText = "Invalid email entered.";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      this.createAccount();
    },

    validateEmail(email) {
      const mailformat =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.toLowerCase().match(mailformat) !== null;
    },

    async createAccount() {
      try {
        let response = await CommonServices.addRecord(
          this.componentURL,
          this.userData,
          false
        );
        if (this.file) {
          const formData = new FormData();
          formData.append("file", this.file);
          formData.append("upload_file_name", this.file.name);
          formData.append("user_id", parseInt(response.id));
          await CommonServices.postData("/UserPhotos", formData);
        }
        this.sendWelcomeEmail();
        this.sendAdminEmail(); //send email to Admin informing them of new user
        this.snackbar = true;
        this.snackColor = "success";
        this.snackText = "Account created successfully";
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.log(error);
        this.snackbar = true;
        this.snackColor = "error";
        try {
          Object.keys(error.data).forEach((key) => {
            this.snackText = error.data[key][0];
          });
        } catch (e) {
          this.snackText = "Something went wrong";
        }
      }
    },

    sendEmails() {
      this.sendWelcomeEmail();
      this.sendAdminEmail();
    },

    async sendWelcomeEmail() {
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.userData.first_name || "New"} ${
          this.userData.last_name || "User"
        }`,
        to_email: this.userData.email,
        subject: "Signup Successful",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",

        html_content:
          "<p>Dear User,</p>\
          <p>Thank you for your recent signup on http://petersonclient.com/! </p>\
          <p>Your login credentials are below:</p>\
          <p> &nbsp; &nbsp; User Name: " +
          this.userData.first_name +
          " " +
          this.userData.last_name +
          "</p>\
          <p>&nbsp; &nbsp; Password:&nbsp;" +
          this.userData.django_pwd +
          "</p>\
          <p><strong>You can now <a href='https://petersonclient.com/login'>login</a> into your account.</strong> </p>",
      });
    },

    async sendAdminEmail() {
      const formData = new FormData();
      formData.append(
        "user_name",
        `${this.userData.first_name || "New"} ${
          this.userData.last_name || "User"
        }`
      );
      formData.append("to_email", "drewlynch11@gmail.com");
      formData.append("subject", "A NEW USER ACCOUNT HAS JUST BEEN CREATD");
      formData.append("file", this.file);
      formData.append("upload_file_name", this.file.name);
      formData.append(
        "text_content",
        "Thanks for your recent signup on http://petersonclient.com/"
      );
      formData.append(
        "html_content",
        "<p>Attention: PetersonClient Admin,</p>\
          <p>A new user has just created an account.  Their login credentials are below:</p>\
          <p> &nbsp; &nbsp; User Name: " +
          this.userData.first_name +
          " " +
          this.userData.last_name +
          "</p>\
          <p>&nbsp; &nbsp; Password:&nbsp;" +
          this.userData.django_pwd +
          "</p>\
          <p><strong>Please review the user's account and setup any required access to Deal records.</strong> </p>"
      );
      await CommonServices.postData("/Twillio/send-email", formData);
    },

    updatePreview(e) {
      var reader,
        files = e.target.files;
      if (files.length === 0) {
        console.log("Empty");
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.profileImageUrl = e.target.result;
      };
      reader.readAsDataURL(files[0]);
      this.file = this.$refs.file.files[0];
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped src="../css/buttons.css"></style>
